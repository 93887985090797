var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-2",attrs:{"justify":"start","no-gutters":""}},[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"space-between","align":"center","no-gutters":""}},[_c('div',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.$t('orders.titles.pageTitle')))])])],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"min-height":"600px"},attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItems,"options":_vm.pagination,"loading":_vm.loading,"items-per-page":20,"sort-by":"label","sort-desc":false,"footer-props":{'items-per-page-options': _vm.itemsPerPage}},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.ref",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ref)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.createdAt).format("YYYY-MM-DD hh:mm:ss"))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('span',[_c('router-link',{attrs:{"to":{name: 'subscriptions/id', params: {id: item.user._id}}}},[_vm._v(_vm._s(_vm._f("truncate")(item.user.person.firstname))+" "+_vm._s(_vm._f("truncate")(item.user.person.name)))])],1):_vm._e()]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount.amount_incl_tax)+"€ ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.state)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary","loading":item._id === _vm.loadingOrderID},on:{"click":function($event){return _vm.downloadInvoice(item._id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("far fa-download")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }