<template>
  <v-container fluid>
    <v-row justify="start" class="ma-2" no-gutters>

      <v-col cols="12" class="mt-3">

        <v-row justify="space-between" align="center" no-gutters>

          <div class="display-1">{{$t('orders.titles.pageTitle')}}</div>

        </v-row>
      </v-col>

      <!--v-col cols="12" class="mt-8">
        <div>
          <v-text-field
            v-model="search"
            append-icon="far fa-search"
            :label="$t('common.inputs.search')"
            clearable
            v-on:click:clear="clearInput"
            @keyup.enter.native="searchTrigger"
            outlined>
          </v-text-field>
        </div>
      </v-col-->

      <!-- Table -->
      <v-col cols="12" class="mt-2">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :loading="loading"
          :items-per-page="20"
          style="min-height: 600px"
          sort-by="label"
          :sort-desc="false"
          :footer-props="{'items-per-page-options': itemsPerPage}"
          class="elevation-1">


          <!-- Ref -->
          <template v-slot:item.ref="{item}">
            {{item.ref}}
          </template>

          <!-- Date -->
          <template v-slot:item.date="{item}">
            {{$moment(item.createdAt).format("YYYY-MM-DD hh:mm:ss")}}
          </template>

          <!-- Label -->
          <template v-slot:item.user="{item}">
            <span v-if="item.user">
              <router-link :to="{name: 'subscriptions/id', params: {id: item.user._id}}">{{item.user.person.firstname | truncate}} {{item.user.person.name | truncate}}</router-link>
            </span>
          </template>

          <!-- Amount -->
          <template v-slot:item.amount="{item}">
            {{item.amount.amount_incl_tax}}€
          </template>

          <!-- State -->
          <template v-slot:item.state="{item}">
            {{item.state}}
          </template>

          <!-- Actions -->
          <template v-slot:item.actions="{item}">

            <!-- Download -->
            <v-btn icon color="primary" :loading="item._id === loadingOrderID" @click="downloadInvoice(item._id)">
              <v-icon small>far fa-download</v-icon>
            </v-btn>

          </template>
        </v-data-table>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
  import vuetifyDatatable from '@/mixins/vuetifyDataTable';
  import dataMixin from "@/mixins/data"
  import formatter from "@/mixins/formatter";
  import utils from "@/mixins/utils";

  export default
  {
    name: "Orders",

    mixins: [vuetifyDatatable, formatter, utils, dataMixin],

    data()
    {
      return {
        loadingOrderID: null,

        headers:
        [

          {
            text: this.$t('orders.fields.headers.ref'),
            value: "ref",
            sortable: false,
            width: "20%"
          },

          {
            text: this.$t('orders.fields.headers.date'),
            value: "date",
            sortable: false,
            width: "20%"
          },

          {
            text: this.$t('orders.fields.headers.subscription'),
            value: "user",
            sortable: false,
            width: "40%"
          },

          {
            text: this.$t('orders.fields.headers.amount'),
            value: "amount",
            sortable: false,
            width: "20%"
          },

          {
            text: this.$t('orders.fields.headers.state'),
            value: "state",
            sortable: false,
            width: "10%"
          },

          {
            text: "",
            value: "actions",
            sortable: false,
            width: "10%"
          }
        ],

        URL_API:
        {
          GET_ALL: "/orders"
        }
      }
    },

    created()
    {
      this.setHead(this.$t('orders.titles.pageTitle'));
    },

    methods:
    {
      downloadInvoice(orderID)
      {
        this.loadingOrderID = orderID;

        this.$http.get(`orders/${orderID}/invoice`,
        {
          responseType: 'arraybuffer',
          headers:
          {
            Authorization: "Bearer " + this.$session.get('jwt')
          }
        })
        .then(res =>
        {
          //console.log(res.data);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'invoice.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(err =>
        {
          this.$store.commit("toast/showError", this.$t('common.errors.500'));
        })
        .finally(() =>
        {
          this.loadingOrderID = null;
        })
      },
    }
  }
</script>

<style scoped>

</style>